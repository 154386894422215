import axios from 'axios';
import { handleLogout } from './auth';
import * as Sentry from "@sentry/react";

let baseURL;

if (process.env.REACT_APP_APP_ENV === 'prd') {
  baseURL = 'https://api.fortifyedu.com/';
} else {
  baseURL = 'http://localhost:5000/';
}

axios.defaults.baseURL = baseURL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const getAccessToken = () => localStorage.getItem('accessToken');

const endpointsWithoutAccessTokenAuthentication = [
    'auth/login',
    'auth/refresh',
    'auth/request-password-reset',
    'auth/reset-password',
];

const shouldAddAuthHeader = (url) => {
    if (!url) {
      return false;
    }

    for (const endpoint of endpointsWithoutAccessTokenAuthentication) {
        if (url.endsWith(endpoint)) {
            return false;
        }
    }
    return true;
};

export class AuthenticationError extends Error {
    constructor(message = 'Authentication required') {
      super(message);
      this.name = 'AuthenticationError';
    }
  }

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  const response = await axios.post('/auth/refresh', {}, {
    headers: {
    Authorization: `Bearer ${refreshToken}`,
    'Content-Type': 'application/json'
    }
  });

  const newToken = response.data.access_token;
  localStorage.setItem('accessToken', newToken);
  return response.data.access_token;
};

/* Interceptor: For 401 errors, retry the request once with a new access token. */
axios.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;

  /*
  If the route didn't require an auth header, or the response wasn't 401, then we're done.
  */
  if (
    !shouldAddAuthHeader(originalRequest?.url)
    || error.response?.status !== 401 
  ) {
    return Promise.reject(error);
  }

  const shouldRetryDueToExpiredAccessToken = (
    error.response?.data.msg === "Token has expired" 
    && !originalRequest._retry
  );

  if (!shouldRetryDueToExpiredAccessToken) {
    handleLogout();
    // We shouldn't retry, but this is unusual, so let's bubble it up where it'll be logged to Sentry
    return Promise.reject(error);
  }

  // Let's try to get an access token with the refresh token, and then retry the request.
  try {
    const accessToken = await refreshToken();
    originalRequest._retry = true;
    originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
    return axios(originalRequest);
  } catch (refreshError) {
    handleLogout();
    // The refresh token may be expired. If so, throw an AuthenticationError (which is not logged)
    throw new AuthenticationError('Refresh token expired');
  }
});

/* Interceptor: add the access token if required */
axios.interceptors.request.use(async config => {
  if (shouldAddAuthHeader(config.url)) {
    const token = getAccessToken();
    if (!token) {
      Sentry.captureException(new Error(`Missing access token for authenticated endpoint: ${config.url}`), {
        tags: {
          endpoint: config.url
        },
        extra: {
          headers: config.headers,
          method: config.method
        }
      });

      handleLogout();
      throw new AuthenticationError('No access token available');
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default axios;
