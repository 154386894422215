import React from 'react';
import { Dialog, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FortifyRatingModal = ({ open, onClose }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="xs" 
      PaperProps={{ 
        style: { 
          borderRadius: '15px',
          width: '98%'
        } 
      }}
    >
      <Box p={4} position="relative">
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" mb={3} fontWeight="bold">
          Fortify Scoring Guide
        </Typography>
        <Typography variant="body1" mb={2}>
          Fortify uses a 10-point scoring system for student creditworthiness, considering factors like credit score, delinquent accounts, and collections history. We categorize students into four tiers for you:
        </Typography>
        <Typography variant="body1" mb={2}>
          - Tier 1 (safer borrowers): <span style={{float: 'right'}}>{'>'}8.5 points</span><br/>
          - Tier 2: <span style={{float: 'right'}}>7.0 to 8.5 points</span><br/>
          - Tier 3: <span style={{float: 'right'}}>5.5 to 6.9 points</span><br/>
          - Tier 4 (riskier borrowers): <span style={{float: 'right'}}>{'<'} 5.5 points</span>
        </Typography>
        <Typography variant="body1" mb={2}>
          Based on these tiers, we provide suggested down payments to help protect your school: from 5% of the tuition for Tier 1 students, to 20%+ for Tier 4 students. However, <b>you always have final control</b> over the final down payment amount.<br/><br/>Use these ratings alongside your school's policies & student circumstances to make informed decisions about the down payment you'll require.
        </Typography>
        <Typography variant="body1" mb={2}>
          <b>Important note #1:</b> Fortify scores and tiers are <b>not</b> a guarantee of repayment performance. They are simply suggestions to help guide your decision-making and minimize your risk.<br/><br/><b>Important note #2: </b>Fortify's evaluations are not intended to influence loan approval or rejection decisions, but only to serve as a tool to guide your determination of the down payment to collect.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default FortifyRatingModal;
