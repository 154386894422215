import React, { useState } from 'react';
import { Dialog, Button, Alert, CircularProgress } from '@mui/material';
import { unarchiveLoanApplication } from '../../../api/applications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';


const UnarchiveModal = ({ 
    open,
    onClose,
    applicationData,
    onActionSuccess,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleUnarchive = async () => {
        setIsLoading(true);
        setError('');
        try {
          await unarchiveLoanApplication(applicationData.schoolId, applicationData.id)
          onClose();
          onActionSuccess();
        } catch (error) {
          logErrorToConsole("Failed to unarchive loan application", error);
          logErrorToSentryWithContext(error, {
            schoolId: applicationData.schoolId,
            applicationId: applicationData.id,
          });
          setError(error.response?.data?.error || 'Failed to unarchive loan application');
        } finally {
          setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '15px' } }}>
            <div className='p-8'>
            <h1 className="text-2xl mb-2"><b>Unarchive {applicationData.student.account.firstName}'s application?</b></h1>
            <div>
            <p className="mb-6">{`Their loan application will return to active, and they'll be able to continue with their application.`}</p>
            </div>
            {error && <Alert severity="error" className="mb-4">{error}</Alert>}
            <div className="flex justify-center">
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div className="flex flex-row w-full justify-between space-x-4">
                        <Button className="flex-grow" variant="outlined" color="primary" onClick={onClose} sx={{ color: 'fortify-green', borderColor: 'fortify-green' }}>
                            Cancel
                        </Button>
                        <Button className="flex-grow" variant="contained" color="primary" onClick={handleUnarchive} sx={{ backgroundColor: 'fortify-green' }}>
                            Unarchive
                        </Button>
                    </div>
                )}
            </div>
        </div>
        </Dialog>
    );
};

export default UnarchiveModal;