import React, { useState, useEffect } from 'react';
import SchoolSelector from './SchoolSelector';
import NavLinks from './NavLinks';
import UserSection from './UserSection';

const Sidebar = ({ selectedTab, handleSelectTab, handleLogout, user, activeSchool, authorizedSchools, setActiveSchool, isCollapsed, toggleCollapse }) => {
  useEffect(() => {
    const handleInitialResize = () => {
      if (window.innerWidth < 1000 && !isCollapsed) {
        toggleCollapse();
      }
    };

    handleInitialResize();
  }, []);

  return (
    <div className={`flex flex-col h-full ${isCollapsed ? 'sidebar-collapsed' : ''}`}>
      <div className="flex-grow overflow-y-auto">
        <div className={`mt-4 ${isCollapsed ? 'mx-2' : 'mx-6'}`}>
          <SchoolSelector
            activeSchool={activeSchool}
            authorizedSchools={authorizedSchools}
            setActiveSchool={setActiveSchool}
            isCollapsed={isCollapsed}
            onExpandClick={() => {
              if (isCollapsed) {
                toggleCollapse()
              }
            }}
          />
        </div>
        <div className={`mt-12 mb-6 ${isCollapsed ? 'mx-2' : 'mx-6'} space-y-2`}>
          <NavLinks
            selectedTab={selectedTab}
            handleSelectTab={handleSelectTab}
            activeSchool={activeSchool}
            isCollapsed={isCollapsed}
          />
        </div>
      </div>
      <UserSection
        user={user}
        onLogout={handleLogout}
        handleSelectTab={handleSelectTab}
        selectedTab={selectedTab}
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
      />
    </div>
  );
};

export default Sidebar;