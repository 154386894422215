import React, { useState } from 'react';
import { Snackbar, Button } from '@mui/material';

const WebCopy = ({ activeSchool }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const applicationLink = activeSchool.custom_application_link
    ? `https://apply.fortifyedu.com/${activeSchool.custom_application_link}`
    : `https://apply.fortifyedu.com?school=${activeSchool.id}`;

  const marketingCopy = `Financial Aid Options with Fortify

We're committed to providing accessible & manageable financing options for our students. That's why we've partnered with Fortify to offer loans tailored for our students.

Fortify provides clear, straightforward, and transparent loan terms to help you understand the full details of your financial commitments without surprises.

To get started, apply through Fortify online, or call them at (214) 644-6444 for more information.`;

  const handleCopy = (text, message) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <div className="flex flex-col w-full overflow-y-auto h-full">
      <div className="page-title">Web Copy</div>
      <hr className='mt-2 line-divider'/>
      <div className="mt-8">
        <p className="mb-6">Here's materials you can use on your website to tell your students about Fortify.</p>
        <div className="space-y-8">
          <div>
            <h2 className="text-xl font-semibold mb-2">Application Link</h2>
            <p className="mb-4">Link your students here to start their loan application process.</p>
            <div className="flex items-center border border-gray-300 rounded-lg bg-gray-100 max-w-[600px]">
              <button 
                onClick={() => handleCopy(applicationLink, 'Application link copied to clipboard!')}
                className="px-3 h-full rounded-l-lg flex items-center justify-center"
              >
                <img src="/copyLink.svg" alt="Copy" className="w-6 h-6 ml-2" />
              </button>
              <div 
                onClick={() => handleCopy(applicationLink, 'Application link copied to clipboard!')}
                className="flex-1 p-4 bg-transparent cursor-pointer hover:text-blue-600 underline"
              >
                {applicationLink}
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">Images</h2>
            <p className="mb-4">Download these images to use on your website.</p>
            <div className="grid grid-cols-2 gap-4 max-w-[500px]">
              <div className="flex flex-col gap-2">
                <div 
                  className="h-[100px] bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors p-4 flex items-center justify-start"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/fortifyMarketingImages/FortifyApplyButton.svg';
                    link.download = 'FortifyApplyButton.svg';
                    link.click();
                  }}
                >
                  <img src="/fortifyMarketingImages/FortifyApplyButton.svg" alt="Fortify Apply Button" className="h-full object-contain" />
                </div>

                <div 
                  className="h-[100px] bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors p-4 flex items-center justify-start"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/fortifyMarketingImages/FortifyHeader.svg';
                    link.download = 'FortifyHeader.svg';
                    link.click();
                  }}
                >
                  <img src="/fortifyMarketingImages/FortifyHeader.svg" alt="Fortify Header" className="h-full object-contain" />
                </div>


              </div>

              <div className="flex flex-col gap-2">
                <div 
                  className="h-[100px] bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors p-4 flex items-center justify-start"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/fortifyMarketingImages/FortifyTransparentHeader.svg';
                    link.download = 'FortifyTransparentHeader.svg';
                    link.click();
                  }}
                >
                  <img src="/fortifyMarketingImages/FortifyTransparentHeader.svg" alt="Fortify Transparent Header" className="h-full object-contain" />
                </div>


                <div 
                  className="h-[100px] bg-gray-50 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors p-4 flex items-center justify-start"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/fortifyMarketingImages/FortifyLogo.svg';
                    link.download = 'FortifyLogo.svg';
                    link.click();
                  }}
                >
                  <img src="/fortifyMarketingImages/FortifyLogo.svg" alt="Fortify Logo" className="h-full object-contain" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-xl font-semibold mb-2">Marketing Copy</h2>
            <p className="mb-4">Safe language to describe Fortify's loan options on your website while staying compliant.</p>
            <div className="flex items-center border border-gray-300 rounded-lg bg-gray-100 max-w-[600px]">
              <button 
                onClick={() => handleCopy(marketingCopy, 'Marketing copy copied to clipboard!')}
                className="px-3 h-full rounded-l-lg flex items-center justify-center"
              >
                <img src="/copyLink.svg" alt="Copy" className="w-6 h-6 ml-2" />
              </button>
              <div className="flex-1 p-6 whitespace-pre-line">
                {marketingCopy}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={2500}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        ContentProps={{
          style: {
            backgroundColor: '#6E6E6F',
            borderRadius: '0.75rem',
            padding: '6px 24px',
            fontSize: '0.875rem',
            fontWeight: 500,
          }
        }}
      />
    </div>
  );
};

export default WebCopy;